body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.pos-rel{
  position: relative;
}
.pos-abs{
  position: absolute;
}
.t-red{
  color: red;
}
.t-blue{
  color: #00b4ff;
}
.t-yellow{
  color: #e79c25;
}
.tp0{
  color: #000;
}
.tp1{
  color: #344a53;
}
.tp2{
  color: #384356;
}
.tp3{
  color: #636d83;
}
.ts1{
  color: #3b4856;
}
.ts2{
  color: #677381;
}
.ts3{
    color: #818e9c;
}
.t-nutral{
  color: #9dadbc;
}
.t-nutral2{
  color: #9da9b8;
}
.f12{
  font-size: 12px;
}
.f14{
  font-size: 14px;
}
.f16{
  font-size: 16px;
}
.f18{
  font-size: 18px;
}
.f20{
  font-size: 20px;
}
.br-1{
  border-radius: 1px;
}
.br-2{
  border-radius: 2px;
}
.br-3{
  border-radius: 3px;
}
.br-4{
  border-radius: 4px;
}
.f-bold{
  font-weight: bold;
}
.bdr-none{
  border: none;
}
.bdr0{
  border: 1px solid #000;
}
.bdr0-t{
  border-top: 1px solid #000;
}
.bdr0-b{
  border-bottom:  1px solid #000;
}
.bdr0-r{
  border-right: 1px solid #000;
}
.bdr0-l{
  border-left: 1px solid #000;
}
.bdr1{
  border: 1px solid #9dadbc;
}
.bdr1-r{
  border-right: 1px solid #9dadbc;
}
.bdrb1{
  border-bottom: 1px solid #9dadbc;
}
.bdr2{
  border: 1px solid #d2eee9;
}
.bdr2-b{
  border-bottom: 1px solid #d2eee9;
}
.bdr2-r{
  border-right: 1px solid #d2eee9;
}
.bdr2-l{
  border-left: 1px solid #d2eee9;
}
.bdrb2{
  border-bottom: 1px solid #d2eee9;
}
.bdrb0{
  border-bottom: none;
}
.just-start{
  justify-content: start;
}
.just-center{
  justify-content: center;
}
.just-between{
  justify-content: space-between;
}
.just-end{
  justify-content: end;
}
.al-start{
  align-items: start;
}
.al-center{
  align-items: center;
}
.al-end{
  align-items: end;
}
.grid-center{
  display: grid;
  align-content: center;
  vertical-align: middle;
}
.grid-split-2{
  display: grid;
  grid-template-columns: auto auto;
}
.grid-date{
  display: grid;
  grid-template-columns: auto 250px;
}
.grid-date-b{
  display: grid;
  grid-template-columns: auto 250px 150px;
}
.tab-border{
  border-left: 1px solid #d2eee9;
  border-right: 1px solid #d2eee9;
  border-bottom: 1px solid #d2eee9;
}
.bgp{
  background-color: #00b4ff;
}
.bgp1{
  background-color: #00cdfb;
}
.bgs1{
  background-color: #ecfbff;
}
.bgs2{
  background-color: #e6f4f1;
}
.bgs1-lite{
  background-color:#ecfbff66;
}
.p-multi-line{
  white-space: pre-line;
}
.sucess-tag{
  color: green;
  background-color: #a6f78b;
  border: 1px solid #3cefb6;
  font-size: 14px;
  padding:4px 8px;
  border-radius: 2px;
}
.bg-ss{
  background-color: #a6f78bba;
}
.process-tag{
  background-color: #00e0e06e;
  border: 1px solid #3cefb6;
  font-size: 14px;
  padding:4px 8px;
  border-radius: 2px;
}
.error-message{
  font-size: 14px;
  background-color: #f8d7da;
  padding:4px 8px;
  border: 1px solid #f5c2c7;
  border-radius: 2px;
  color: red;
}
.error-alert{
  font-size: 18px;
  color: red;
}
.error-text{
  color: red;
}
.sucess-alert{
  font-size: 18px;
  color: green;
}
.sucess-text{
  color: green;
}

.justify-self-center{
  justify-self: center;
}
.wrapper{
  height: 100vh;
  display: grid;
  align-content: center;
}
.grid-center{
  display: grid;
  align-content: center;
  vertical-align: middle;
}
.login-window{
  border: 1px solid #00b4ff;
  box-shadow: 1px 1px 3px #00000038;
}
.login-btn{
  background-color: #f9f871;
  border: 1px solid #f9f871;
  border-radius: 3px;
  color: #344a53;
}
.login-btn:hover{
  background-color: #00cdfb;
  color: #FFF;
  border: 1px solid #FFF;
}
.login-form input[type="text"],
.login-form input[type="password"]{
  background-color: #e6fdff;
  border-radius: 3px;
}
.login-form input[type="text"]:focus,
.login-form input[type="password"]:focus{
  outline: none;
}
.menu1{
  border: none;
}
.main-nav{
  box-shadow: 1px 1px 3px #00b4ff;
  background-color: #ecfbff;
}
.nav-brand{
  background-color: #00b4ff;
  color: #FFF;
  padding: 2px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.dashboard-wraper{
  display: grid;
  grid-template-rows: 59px auto;
  height: 100vh;
}
.quick-link{
  display: flex;
  flex-direction: inherit;
}
.quick-link a{
  text-decoration: none;
}
.link-btn{
  justify-content:center;
  align-content: center;
  text-decoration: none;
}
.tokens{
  display: grid;
  grid-template-columns: auto auto;
  gap: .8rem;
  padding: .8rem;
  overflow: hidden;
}
.token-item{
  border: 1px solid #00b4ff;
  border-radius: 4px;
  display: grid;
  grid-template-rows: 55px auto;
  overflow: hidden;
}
.token-item-header{
  background-color: #00b4ff;
  color: #FFF;
  padding-left:10px;
  display: grid;
  align-content: center;
  align-items: center;
}
.token-item-header-new{
  background-color: #00b4ff;
  color: #FFF;
  padding:10px;
  display: grid;
  grid-template-columns: auto 100px;
  align-content: center;
  align-items: center;
}
.token-item-body{
  background-color: #f2faff;
}
.open-btn{
  background-color: #00b4ff;
  border: 1px solid #00b4ff;
  border-radius: 3px;
  padding: 4px 10px;
  color: #FFF;
}
.service-add-form{
  display: grid;
  grid-template-columns: auto 80px 150px;
  gap: 1rem;
}
.service-add-form-special{
  display: grid;
  grid-template-columns: auto 120px 80px 150px;
  gap: 1rem;
}
.service-search-wrapper{
 background-color: #ecfbff;
  border: 1px solid #00e0e0;
  border-radius: 3px;
}
.service-add-form input[type="text"],
.service-add-form input[type="number"]{
  border-radius: 2px;
}
.btn-t1{
  border-radius: 2px;
  background-color: #00b4ff;
  border: 1px solid #00b4ff;
  cursor: pointer;
  color: #FFF;
  transition: all ease 0.2s;
}
.btn-t1:hover{
  background-color: #FFF;
  color: #00b4ff;
  border: 1px solid #00b4ff;
  transition: all ease 0.2s;
}
.btn-t1-r{
  border-radius: 2px;
  background-color: #FFF;
  border: 1px solid #00b4ff;
  cursor: pointer;
  color: #00b4ff;
  transition: all ease 0.2s;
}
.btn-t1-r:hover{
  background-color: #00b4ff;
  color: #FFF;
  border: 1px solid #00b4ff;
  transition: all ease 0.2s;
}
.btn-disable{
  border-radius: 2px;
  background-color: #FFF;
  color: #ff8271;
  border: 1px solid #ff8271;
}
.btn-disable:hover{
  background-color: #ff8271;
  color: #FFF;
  border: 1px solid #ff8271;
}
.btn-enable{
  border-radius: 2px;
  background-color: #FFF;
  color: #00c396;
  border: 1px solid #00c396;
  transition: all ease 0.2s;
}
.btn-enable:hover{
  background-color: #00c396;
  color: #FFF;
  border: 1px solid #00c396;
  transition: all ease 0.2s;
}
.btn-add-token{
  border-radius: 3px;
  background-color: #FFF;
  border: 1px solid #00b4ff;
  cursor: pointer;
  color: #00b4ff;
  transition: all ease 0.2s;
}
.ac-head{
  padding:1rem;
}
.table td.bgy{
  background-color: #f9f871;
}
.bg-total{
  background-color: #f9f871;
  border: 1px solid #d4a418;
}
.qty-edit-icon{
  background-color: #e6f4f1;
  color: #00b4ff;
  display: grid;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
  left: 0;
  top: 0;
  align-content: center;
  cursor: pointer;
  display: none;
}
.td-qty:hover > .qty-edit-icon{
  display: grid;
}
.td-tc:hover > .qty-edit-icon{
  display: grid;
}
.td-sc:hover > .qty-edit-icon{
  display: grid;
}
.tbl-bill{
  width: calc(100% - 35px);
}
.tbl-bill > tbody > tr:hover{
  background-color: #f2faff;
}
.remove-service{
  top: 0;
  right: -37px;
  margin: auto;
  background-color: #FFF;
  height:100%;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px solid #CCC;
  border-radius: 3px;
  padding: 2px 8px;
  color: red;
  cursor: pointer;
  align-items: center;
}
.remove-service:hover{
  background-color: #e6f4f1;
}
.token-add-service-show{
  display: grid;
  grid-template-columns: auto 100px;
  border: 1px solid #00b4ff;
}
.tbl-bg-blue{
  background-color: #ecfbff78;
  border: 1px solid #9dadbc;
}
.tbl-bg-blue > tbody > tr:hover{
  background-color: #e6f4f1;
}
.staff-type-header{
  grid-template-columns: auto 150px;
  align-items: center;
}
.service-type-header{
  grid-template-columns: auto 150px 150px 150px;
  align-items: center;
  gap: 10px;
}
.staff-type-item{
  grid-template-columns: auto 100px;
  gap: 1rem;
  align-items: center;
}
.customer-item{
  display: grid;
  align-items: center;
  grid-template-columns: auto 150px 100px;
  gap: 1rem;
}
.staff-item{
  grid-template-columns: auto 180px 80px 110px;
  gap: 1rem;
  align-items: center;
}
.staff-type-item:hover{
  background-color: #ecfbff78;
}
.menu-one > .dropdown-item{
  display: grid;
  grid-template-columns: 30px auto;
}
.permission-item{
  background-color: #e6f4f1;
  justify-content: center;
  border: 1px solid #d9ece9;
  position: relative;
  cursor: pointer;
}
.permission-item > i{
  display: none;
}
.permission-item.active{
  background-color: #3cefb6;
  border: 1px solid #3cefb6;
}
.permission-item.active > i{
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  color: green;
  margin: auto;
  padding: 1px 5px;
}
.permi-op{
  width: 120px;
}
.permi-name{
  display: grid;
  align-items: center;
}
.service-item{
  grid-template-columns:auto 100px 100px;
  gap: 1rem;
  align-items: center;
}
.enquiry-item{
  grid-template-columns:auto 200px;
  gap: 1rem;
  align-items: center;
}
.service-item:hover,
.enquiry-item:hover{
  background-color: #ecfbff78;
}
.accordion-button:focus,
.form-control:focus {
    box-shadow: none;
}
.please-wait-spinner > .spinner-border{
  border-width: 2px;
}
.pos-rel{
  position: relative;
}
.customer-search-results{
  position: absolute;
  width: 100%;
  height: auto;
  top: 100%;
  z-index: 100;
  background-color: #FFF;
  min-height: 250px;
  border-top: none;
}
.customer-search-results span{
  padding: 10px;
  cursor: pointer;
}
.customer-search-results span.active{
  background-color: #7cfac3;
}
.customer-search-results span:hover{
  background-color: #d2eee9;
}
.token-service-item{
  display: grid;
  grid-template-columns: 130px auto 100px;
}
.new-token-service-item{
  display: grid;
  grid-template-columns: 100px auto 100px 100px;
}
.token-service-item-cancel{
  display: grid;
  grid-template-columns: 130px auto 130px;
}
.new-token-item{
  display: grid;
  grid-template-columns: 130px auto 200px;
}
.item-tid{
  display: flex;
  flex-flow: column;
  padding: 10px;
}
.item-tservice{
  display: flex;
  flex-flow: column;
  padding: 10px;
}
.item-toption{
  display: grid;
  padding: 10px;
}
.item-toption-add{
  display: grid;
  padding: 10px;
  justify-content: center;
  align-content: center;
}
.qty-update-div{
  width: 250px;
  display: grid;
  grid-template-columns: auto 80px;
  gap: 10px;
  float: right;
}
.qty-update-form{
  display: grid;
  grid-template-columns: auto 50px;
  gap: 10px;
}
.grid-auto-100{
  display: grid;
  grid-template-columns: auto 100px;
}
.grid-auto-150{
  display: grid;
  grid-template-columns: auto 150px;
}
.grid-auto-100:hover,
.grid-auto-150:hover{
  background-color: #d2eee9;
}
.grid-justify-end{
  display: grid;
  justify-content: end;
}
.collection-status{
  background-color: #eafbff;
  border: 1px solid #00e0e0;
}
.load-coll{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 200px;
  text-align: center;
}
.diff{
  background-color: #f9f871;
}
.denomin{
  display: grid;
  grid-template-columns: 80px auto;
  align-items: center;
}
.special-token-taskbar{
  display: grid;
  grid-template-columns: auto 200px;
  gap: 1rem;
  align-items: center;
}
#printable{
  width: 100%;
}
.print-token-header{
  display: grid;
  grid-template-columns:auto 20%;
  align-items: center;
}
.print-token-header-logo{
  overflow: hidden;
  display: grid;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}
.print-token-header-logo img{
  width: 100%;
  max-width: 150px;
}
.print-token-body{
  display: grid;
  grid-template-columns: 30% auto;
}
.print-token{
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
}
.print-token-details{
  display: grid;
  align-items: center;
}
.print-token-name{
  position: absolute;
  font-size: 1.5rem;
  font-weight: 600;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;

}
.print-token-number{
  display: grid;
  justify-content: center;
  font-size: 8rem;
  font-weight: 600;
}
.print-token-detail-item{
  display: grid;
  grid-template-columns: 120px 15px auto;
}
.show-logo img{
  width: 150px;
}
.bill-signature{
  justify-content: end;
}
.closed-item-staff{
  display: grid;
  align-items: center;
}
.view-bill-link{
  background-color: #00b4ff;
  border: 1px solid #00b4ff;
  color: #FFF;
  border-radius: 2px;
  padding: 4px 10px;
  margin: 5px;
  cursor: pointer;
  min-width: 70px;
}
.view-bill-link-credit{
  display: flex;
  background-color: #00b4ff;
  border: 1px solid #00b4ff;
  color: #FFF;
  border-radius: 2px;
  padding: 4px 10px;
  cursor: pointer;
  min-width: 70px;
  justify-content: center;
  align-content: center;
}
.view-bill-link:hover{
  background-color: #00e0e0;
  color: #18504a;
  transition: all ease 0.2s;
}
.invoice-cus-details{
  display: grid;
  grid-template-columns: auto auto;
}
.credit-paid{
  padding: 4px 8px;
}
.quick-link-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sms-div{
  position: relative;
}
.sms-icon{
  cursor: pointer;
}
.sms-icon:hover{
  background-color: #CCC;
}
.sms-list-container{
  position: absolute;
  top: 30px;
  right: 0%;
  min-width: 300px;
  z-index: 1000;
}
.sms-list-conteiner-header{
  display: grid;
  grid-template-columns: auto 32px;
  align-items: center;
}
.refresh-sms{
  cursor: pointer;
}
.sms-head button{
  display: grid;
  align-items: center;
  grid-template-columns: auto 30px;
  padding: 5px 10px;
}
.pagination-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
}
.pageination-index{
  width: 45px;
  height: 45px;
  border: 2px solid #00b4ff;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 100%;
  cursor: pointer;
}
.pageination-index:hover{
  background-color: #00b4ff;
  color: #FFF;
}
.pageination-index.active{
  background-color: #00b4ff;
  color: #FFF;
}
.date-select{
  display: grid;
  justify-content: end;
}
.pay-wall-wrap{
  display: grid;
  grid-template-columns: auto 300px;
}
.bdr-red{
  border-color: red;
}